import React from "react"
import { Layout, SEO } from "../components/structure"
import { Hero, Content, CallToAction, Button, SeatBlocker, SpeedPass, StayPlus } from "../components/blocks"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import { mq, brand, font } from "../styles"
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import IconsList from "../components/blocks/icons/Icons"



const ProductsPage = () => {


  const pageQuery = useStaticQuery(graphql`
    query {
      heroImg: file(base: { eq: "Plusgrade-Hero-Banner-Products.jpg" }) {
        ...FullHeroBackground
      }
      rightArrowIconWhite: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
    AncilRevIconOne: file(base: { eq: "certified.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconTwo: file(base: { eq: "guage.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconThree: file(base: { eq: "revenueOpp.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconFour: file(base: { eq: "noEntry.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconFive: file(base: { eq: "bullseye.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconSix: file(base: { eq: "barChartIncrease.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      AncilRevIconSeven: file(base: { eq: "loyalty-circles.png" }) {
        childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100){
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      HeroRightArrow: file(base: {eq: "plusgrade-blue-arrow.png"}) {
        childImageSharp {
            fluid(maxWidth: 500, webpQuality: 100){
                ...GatsbyImageSharpFluid_withWebp
            }
        }
      }
      premUpgradeIcon: file(base: { eq: "prem_upgrade_icon_orange.png" }) {
        childImageSharp {
            fixed(width: 50, height: 50, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
        }
      }

      
      mobileDevice: file(base: { eq: "Product-Premium-Upgrade.png" }) {
        childImageSharp {
            fixed(width: 230, height: 290, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
      }
    airButton: file(base: { eq: "airButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cruiseButton: file(base: { eq: "cruiseButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    railButton: file(base: { eq: "railButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    hospitalityButton: file(base: { eq: "hospitalityButton.png" }) {
      childImageSharp {
        fixed(width: 15, height: 15, webpQuality: 100){
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    speedPassProduct: file(base: { eq: "Product-Speed-Pass.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    lcrProductImage: file(base: { eq: "LCR-Device-SWA.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    StayExtendImage: file(base: { eq: "StayExtended-Mobile-2023.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    StayPlusImage: file(base: { eq: "Resized%20-%20StayPlus.png" }) {
      childImageSharp {
          fixed(width: 260, height: 495, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    loyaltyIcon: file(base: {eq: "handPlus.png"}){
      childImageSharp {
          fixed(width: 60, height: 60, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    stayExtendIcon: file(base: {eq: "Icons_Product_hospitality_final_StayExtend.png"}){
      childImageSharp {
          fixed(width: 50, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    platformIcon: file(base: {eq: "holdUp.png"}){
      childImageSharp {
          fixed(width: 60, height: 51, webpQuality: 100) {
              ...GatsbyImageSharpFixed_withWebp
          }
      }
    }
    rightArrowIcon: file(base: {eq: "arrow-right.png"}) {
        ...ButtonIcon
      }
    platformPartnershipDevice: file(base: { eq: "PlatformPartnershipDevice.png" }) {
      childImageSharp {
          fluid(maxWidth: 440, webpQuality: 100) {
              ...GatsbyImageSharpFluid_withWebp
          }
      }
  }


}`)

  const GetStartedContainer = styled.div`
  
  ${ font.imports.sherika }

   width: 95%;
   max-width: 1400px;
   margin: 40px auto;

   h3 {
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 48px;
    text-align: left;
    color: ${brand.colors.pg_blue};
    padding-right: 140px;

    @media only screen and ${mq.maxMd} {
      font-size: 32px;
      text-align: center;
      padding-right: 0;
    }
   }
   p{
    font-family: ${font.family.sherika};
    font-weight: 400;
    font-size: 26px;
    text-align: left;
    padding-right: 100px;

    @media only screen and ${mq.maxMd} {
      font-size: 20px;
      text-align: center;
      padding-right: 0;
    }
   }

   >span {
    @media only screen and ${mq.maxMd} {
      margin: auto;
      display: block;
      width: 300px;
    }
   }
  `;

  const AncillaryRevDetailContainer = styled.div`
  
  ${ font.imports.sherika }

  padding: 60px 0;

  h2 {
    font-family: ${font.family.sherika};
    font-weight: 700;
    font-size: 56px;
    color: ${brand.colors.pg_orange};
    text-align: left;
    width: 95%;
    max-width: 900px;
    margin: 0 auto 80px;
    @media only screen and ${mq.maxMd} {
      text-align: center;
    }
  }

  .info-container-outer {
    max-width: 900px;
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .info-container {
        flex-basis: 46%;

        @media only screen and ${mq.maxMd} {
          
          max-width: 450px;
          width: 95%;
        }

        .content-con {
            h4 {
                font-family: ${font.family.sherika};
                font-weight: 700;
                font-size: 26px;
                color: ${brand.colors.pg_primary_dark};
                white-space: pre-wrap;
                @media only screen and ${mq.maxMd} {
                  text-align: center;
                }
            }

            p {
                font-family: ${font.family.sherika};
                font-weight: 400;
                font-size: 16px;
                color: ${brand.colors.pg_primary_dark};
                @media only screen and ${mq.maxMd} {
                  text-align: center;
                }
            }
        }

        .icon-con {
            margin-bottom: 20px;
            @media only screen and ${mq.maxMd} {
              text-align: center;
              margin: auto;
            }
        }
    }
  }
  `;

  const ProductsHeroContainer = styled.div`
  width: 100%;
  max-width: 1400px;
  margin: 80px auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;

  @media only screen and ${mq.maxMd} {
    flex-direction: column;
  }

  > div {
    flex-basis: 48%;
  }

  .graphic-con {
    width: 100%;
    max-width: 495px;
    height: 470px;

    @media only screen and ${mq.maxMd} {
      align-self: flex-start;
    }
    
    .gatsby-image-wrapper {
        width: 100% !important;
        height: 100% !important;
    }
  }

  .title-con {
    h2 {
        white-space: pre-wrap;
        font-family: ${font.family.sherika};
        font-weight: 700;
        font-size: 80px;
        color: ${brand.colors.pg_blue};
        padding-top: 135px;
        @media only screen and ${mq.maxMd} {
          text-align: center;
          font-size: 60px;
        }
    }
  }
  
  `;

  const ProductContainerPremUpgrade = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    width: 95%;
    max-width: 1200px;
    margin: auto;
    padding: 80px 0 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column;
      align-items: center;
    }

    > div {
        flex-basis: 48%;
    }

    .graphic-con {
        .graphic-holder {
            width: 316px;
            height: 395px;
            float: right;
            clear: both;

            @media only screen and ${mq.maxMd} {
              float: none;
            }

            .gatsby-image-wrapper {
                width: 100% !important;
                height: 100% !important;
            }
        }   
    }

    .content-con {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @media only screen and ${mq.maxMd} {
          align-items: center;
        }

        .copy > h2 {
            font-family: ${font.family.sherika};
            font-weight: 600;
            font-size: 36px;
            color: ${brand.colors.pg_primary_dark};
            margin: 20px 0;
            @media only screen and ${mq.maxMd} {
              text-align: center;
            }
        }

        .copy > p {
            font-family: ${font.family.sherika};
            font-weight: 400;
            font-size: 20px;
            line-height: 22px;
            color: ${brand.colors.pg_secondary_dark};
            max-width: 530px;
            @media only screen and ${mq.maxMd} {
              text-align: center;
            }
        }

        .button-con {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin-bottom: 35px;
            @media only screen and ${mq.maxMd} {
              align-items: center;
              justify-content: center;
            }

            @media only screen and (max-width: 550px) {
              flex-direction: column;
              width: 95%;
            }

            > a {
                flex-basis: 40%;
                background-color: ${brand.colors.pg_orange};
                width: 100px;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                gap: 0 10px;
                padding: 10px;
                border-radius: 26px;
                font-family: ${font.family.sherika};
                font-weight: 700;
                font-size: 20px;
                color: #fff;

                @media only screen and (max-width: 550px) {
                  width: 100%;
                  justify-content: flex-start;
                }

                > span {
                  @media only screen and (max-width: 550px) {
                    position: relative;
                    left: 35%;
                  }
                }

                > svg {
                  @media only screen and (max-width: 550px) {
                    position: relative;
                    left: 35%;
                  }
                }
            }


        }
    }
  `;

  const ProductsContainerLCR = styled.div`
  
  ${ font.imports.sherika }
    max-width: 1200px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2%;
    padding-top: 80px;

    @media only screen and ${mq.maxMd} {
      width: 95%;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
    }

    .left-con, .right-con {
      flex-basis: 48%;
    }

    .left-con {
      display: flex;
      justify-content: center;
      padding-right: 130px;
      @media only screen and ${mq.maxMd} {
        padding-right: 0;
      }
    }

    .right-con {
      

      
      .icon-con {

        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: left;
        }
      }

      
      .copy {
        h2 {
          font-family: ${font.family.sherika};
          font-weight: 600;
          font-size: 36px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        p {
          font-family: ${font.family.sherika};
          font-weight: 400;
          font-size: 20px;
          color: ${brand.colors.pg_secondary_dark};
          line-height: 22px;
          max-width: 450px;
          margin-bottom: 30px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
      }
      span {
        margin: auto;

        @media only screen and (max-width: 500px) {
            width: 225px;
        }
      }
    }
  `;

  const ProductsContainerLPP = styled.div`
  
  ${ font.imports.sherika }
    width: 95%;
    max-width: 1200px;
    margin: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0 2%;
    padding: 60px 0;

    @media only screen and ${mq.maxMd} {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      padding-bottom: 0;
    }

    .left-con, .right-con {
      flex-basis: 48%;
    }

    .left-con {
      display: flex;
      justify-content: center;
      padding-left: 130px;
      @media only screen and ${mq.maxMd} {
        padding-left: 0;
        flex-basis: 180px;
        width: 95%;
        max-width: 450px;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
        overflow: visible !important;
      }
    }

    .right-con {

      @media only screen and ${mq.maxMd} {
        width: 95%;
        max-width: 450px;
        margin: auto;
      }
      
      .icon-con {
        margin: auto;
        text-align: center;
        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
        }
      }
      .copy {
        h2 {
          font-family: ${font.family.sherika};
          font-weight: 600;
          font-size: 36px;
          color: ${brand.colors.pg_primary_dark};
          white-space: pre-wrap;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }

        p {
          font-family: ${font.family.sherika};
          font-weight: 400;
          font-size: 20px;
          color: ${brand.colors.pg_secondary_dark};
          line-height: 22px;
          margin-bottom: 30px;
          @media only screen and ${mq.maxMd} {
            text-align: center;
          }
        }
      }
      >span {
        @media only screen and ${mq.maxMd} {
          margin: auto;
          text-align: center;
          display: block;
          width: 300px;
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO
        title={'Products | Plusgrade'}
        description={'Super-charge your ancillary revenue with Plusgrade\'s seamless, white-label solutions.'}
        keywords={'products, services, solutions'}
      />
      <Hero 
        title={"Products"}
        subtitle={''}
        image={pageQuery['heroImg']}
        fullBG
        imageStyle={{objectPosition: "right top"}}
      />

      <Content>
        <GetStartedContainer>
            <h3>{"Super-charge your ancillary revenue with seamless,\nwhite-label solutions"}</h3>
            <p>Our industry-leading products are trusted by the leading travel brands across air, rail, cruise, and hospitality to deliver incredible customer experiences and high-margin revenue.</p>

            <Button destination={'/contact/'} label='Get Started' icon={pageQuery['rightArrowIconWhite']} blue whiteText pill />
        </GetStartedContainer>
      </Content>

      <Content whiteBG noMargin>
        <AncillaryRevDetailContainer>
            <h2>Drive ancillary revenue</h2>

            <div className="info-container-outer">

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconOne'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>White-label solutions</h4>
                        <p>Deliver fully-branded, five-star customer experiences.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconTwo'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Quick integration</h4>
                        <p>Seamlessly fits within your existing systems in a matter of weeks.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconThree'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Direct revenue stream</h4>
                        <p>Maintain control as the Merchant of Record.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconFour'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>No upfront costs</h4>
                        <p>Benefit from a zero-risk commercial structure.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconFive'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>{'Intelligent targeting &\ndecisioning'}</h4>
                        <p>Tap into collective intelligence across 200+ partners.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconSix'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>{'Proven year-over-year\nrevenue growth'}</h4>
                        <p>Maximize profit with diversified products and revenue streams.</p>
                    </div>
                </div>

                <div className="info-container">
                    <div className="icon-con">
                        <GatsbyImage fixed={pageQuery['AncilRevIconSeven'].childImageSharp.fixed} />
                    </div>
                    <div className="content-con">
                        <h4>Loyalty program integration</h4>
                        <p>The only company with ancillaries and loyalty under one roof. Offer points/miles as a payment option, and integrate the ancillaries that bring happiness to your customers.</p>
                    </div>
                </div>
            </div>
        </AncillaryRevDetailContainer>
      </Content>

      <Content noMargin>
        <ProductsHeroContainer>
            <div className="graphic-con">
                <GatsbyImage fluid={pageQuery['HeroRightArrow'].childImageSharp.fluid} imgStyle={{objectPosition: "right center"}}/>
            </div>
            <div className="title-con">
                <h2>{"Our\nproducts"}</h2>
            </div>
        </ProductsHeroContainer>
      </Content>

      <Content secondaryLightBG noMargin>
        <ProductContainerPremUpgrade>
            <div className="content-con" id='premium-upgrade'>
                <div classname="icon-con">
                    <GatsbyImage fixed={pageQuery['premUpgradeIcon'].childImageSharp.fixed} imageStyle={{objectPosition: "center bottom"}} />
                </div>
                <div className="copy">
                    <h2>Premium Upgrade</h2>
                    <p>
                    Elevate trip experiences by offering passengers the ability to bid for or purchase an upgrade. <strong>Premium Upgrade</strong> is the most widely adopted next-generation revenue upgrade solution in the world.
                    </p>
                </div>
                <div className="button-con">
                    <Link to={'/industries/air/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['airButton'].childImageSharp.fixed} /></span><span className="button-label-con">Air</span></Link>
                    <Link to={'/industries/hospitality/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['hospitalityButton'].childImageSharp.fixed} /></span><span className="button-label-con">Hospitality</span></Link>
                    <Link to={'/industries/cruise/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['cruiseButton'].childImageSharp.fixed} /></span><span className="button-label-con">Cruise</span></Link>
                    <Link to={'/industries/rail/'}><span className="button-icon-con"><GatsbyImage fixed={pageQuery['railButton'].childImageSharp.fixed} /></span><span className="button-label-con">Rail</span></Link>
                    

                </div>
            </div>
            <div className="graphic-con">
                <div className="graphic-holder">
                    <GatsbyImage fixed={pageQuery['mobileDevice'].childImageSharp.fixed} imgStyle={{top: '10%'}}/>
                </div>
            </div>
        </ProductContainerPremUpgrade>
      </Content>
      <Content whiteBG e2e id='dynamic-seat-blocker'>
        <SeatBlocker 
            copy={'Deliver more elbow room by enabling passengers to block the seat or row next to them. <strong>Seat Blocker</strong> creates a long-term stream of ancillary revenue from your Economy cabin that will make inventory waste a thing of the past.'}
            buttonLabel={'Learn more'}
            buttonDestination={'/industries/air/'}
        />
      </Content>

      <Content secondaryLightBG noMargin >
        <SpeedPass 
          copy={'Give everyday passengers that VIP feeling by offering travelers the option to access priority check-in and boarding, and navigate security stress-free. <strong>SpeedPass</strong> leverages existing integrations and customer touchpoints to monetize the airport journey.'}
          buttonLabel={'Learn more'}
          graphicStyle = {{objectPosition: "center top", top: '10%'}}
          image={pageQuery['speedPassProduct']}
           id='speed-pass'
          buttonDest={'/industries/air/'}
          style={{marginTop: "-60px"}}
          productPage
        />
      </Content>

      <Content whiteBG noMargin id='stay-extended'>
        <ProductsContainerLCR>
          <div className="left-con">
            <GatsbyImage fixed={pageQuery['StayExtendImage'].childImageSharp.fixed} imgStyle={{objectPosition: "center 25%", top: '10%'}} />
          </div>
          <div className="right-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['stayExtendIcon'].childImageSharp.fixed} />
            </div>
            <div className="copy">
              <h2>{'StayExtend'}</h2>
              <p>Give your guests more flexibility by offering the ability to arrive early, extend their stay, or pay for late check-out. <strong>StayExtend</strong> enables effortless monetization of stay duration changes, and combats soft occupancy rates.</p>
            </div>

            <Button destination={'/industries/hospitality/stayextend'} label={'Learn more'} target={'_blank'} rel={'noreferer'} orange whiteText pill />
          </div>
        </ProductsContainerLCR>
      </Content>


      <Content secondaryLightBG noMargin >
        <StayPlus 
          copy={'Unlock a world of enhanced services for your guests, ensuring every stay is a memorable one. <strong>StayPlus</strong> opens up the ability to seamlessly upsell guests on a variety of perks, including spa visits, dining reservations, amenity rentals, and more. '}
          buttonLabel={'Learn more'}
          graphicStyle = {{objectPosition: "center top", top: '10%'}}
          image={pageQuery['StayPlusImage']}
          id='speed-passx'
          buttonDest={'/industries/hospitality/stayplus'}
          style={{marginTop: "-60px"}}
          productPage
        />
      </Content>

      <Content whiteBG noMargin id='loyalty-currency-retailing'>
        <ProductsContainerLCR>
          <div className="left-con">
            <GatsbyImage fixed={pageQuery['lcrProductImage'].childImageSharp.fixed} imgStyle={{objectPosition: "center 25%", top: '10%'}} />
          </div>
          <div className="right-con">
            <div className="icon-con">
              <GatsbyImage fixed={pageQuery['loyaltyIcon'].childImageSharp.fixed} />
            </div>
            <div className="copy">
              <h2>{'Loyalty Currency\nRetailing'}</h2>
              <p>Enable loyalty program members to do more with their points and miles, while driving customer satisfaction and high-margin revenue. <strong>Loyalty Currency Retailing</strong> by Points, a Plusgrade company, turns singular earn-focused loyalty rewards programs into multi-product revenue drivers.</p>
            </div>

            <Button destination={'https://www.points.com'} label={'Visit Points to learn more'}  faIcon={IconsList.external} target={'_blank'} rel={'noreferer'} orange whiteText pill />
          </div>
        </ProductsContainerLCR>
      </Content>
      
      <Content secondaryLightBG e2e>
        <ProductsContainerLPP>
          <div className="left-con">
              <GatsbyImage fluid={pageQuery['platformPartnershipDevice'].childImageSharp.fluid} imgStyle={{objectPosition: "center bottom", top: "10%", overflow: "visible"}} />
            </div>
            <div className="right-con">
              <div className="icon-con">
                <GatsbyImage fixed={pageQuery['platformIcon'].childImageSharp.fixed} />
              </div>
              <div className="copy">
                <h2>{'Loyalty Commerce\nPlatform'}</h2>
                <p>The <strong>Loyalty Commerce Platform</strong> by Points, a Plusgrade company, gives members the ability to exchange, earn, and redeem points/miles on ancillaries and through third-party or co-branded channels. By connecting your program to a network of industry-leading merchant partners you can grow ancillary revenue and expand the utility of your points/miles.</p>
              </div>

              <Button destination={'https://www.points.com'} label={'Visit Points to learn more'}  faIcon={IconsList.external} target={'_blank'} rel={'noreferer'} orange whiteText pill />
            </div>
        </ProductsContainerLPP>
      </Content>
      <CallToAction
          title={'Drive ancillary revenue\nwith incredible customer experiences'}
          subtitle={'Contact us today to learn more.'}
          > 
          <Button destination={'/contact/request-a-demo'} label={'Request a demo'} icon={pageQuery['rightArrowIconBlue']} white blueText pill />
      </CallToAction>
    </Layout>
  )
}

export default ProductsPage
